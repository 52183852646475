import React from 'react'
import './style.scss'
import { motion } from 'framer-motion'

const Hero = () => {
  const slideVariants = {
    initial: {
      y: 150,
      opacity: 0,
      scale: .5,
    },
    animate: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        duration: .7,
      }
    }
  }
  return (
    <div className='hero-wrapper' style={{
      background: 'radial-gradient(circle, rgba(185,17,18,1) 0%, rgba(130,12,13,1) 60%, rgba(0,0,0,1) 100%)'

    }}>
      <div className="hero-container" style={{
        height: '100%',
      }}>
        <div className="sides">
          <motion.div
            className="hero-content"
            variants={slideVariants}
            initial="initial"
            whileInView="animate">
            <h1>100%</h1>
            <p>Naturali</p>
          </motion.div>
          <motion.div
            className="hero-content"
            variants={slideVariants}
            initial="initial"
            whileInView="animate">
            <h1>100%</h1>
            <p>Naturali</p>
          </motion.div>
          <motion.div
            className="hero-content"
            variants={slideVariants}
            initial="initial"
            whileInView="animate">
            <h1>100%</h1>
            <p>Naturali</p>
          </motion.div>
        </div>
        <div className="mid">
          <img src="/assets/img/bck/bck.png" alt="" className='mid-bck' style={{
            opacity: .2
          }} /><motion.div
          variants={slideVariants}
          initial="initial"
          whileInView="animate"
          style={{
            zIndex: 111
          }}>
          <img src="/assets/img/products/strawberry.png" alt="" className='mid-product' />
        
          </motion.div>
        </div>
        <div className="sides">
          <motion.div
            className="hero-content"
            variants={slideVariants}
            initial="initial"
            whileInView="animate">
            <h1>100%</h1>
            <p>Naturali</p>
          </motion.div>
          <motion.div
            className="hero-content"
            variants={slideVariants}
            initial="initial"
            whileInView="animate">
            <h1>100%</h1>
            <p>Naturali</p>
          </motion.div>
          <motion.div
            className="hero-content"
            variants={slideVariants}
            initial="initial"
            whileInView="animate">
            <h1>100%</h1>
            <p>Naturali</p>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Hero