import React, { useEffect } from "react";
import { Banner, Fruits, Galery, Hero, HeroBlog, Testimonials, Vegetable, VideoBck } from "../Components";
import './style.scss'

const HomePage = () => {
  window.scrollTo({ top: 0 })
  useEffect(() => {
    let lastScrollTime = 0;

    const handleScroll = (event) => {
      const currentTime = new Date().getTime();
      if (currentTime - lastScrollTime < 50) return;
      lastScrollTime = currentTime;

      const delta = event.deltaY;
      const windowHeight = window.innerHeight;
      const components = document.querySelectorAll('.component');
      const currentScroll = window.scrollY;

      let nextScroll = currentScroll;

      if (delta > 0) {
        components.forEach((component) => {
          const componentTop = component.getBoundingClientRect().top;
          if (componentTop > 0 && componentTop <= windowHeight) {
            nextScroll = currentScroll + componentTop;
          }
        });
      } else {
        for (let i = components.length - 1; i >= 0; i--) {
          const componentTop = components[i].getBoundingClientRect().top;
          if (componentTop < 0) {
            nextScroll = currentScroll + componentTop;
            break;
          }
        }
      }

      window.requestAnimationFrame(() => {
        window.scrollTo({
          top: nextScroll,
          behavior: 'smooth'
        });
      });
    };

    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="component"><Banner /></div>
      <div className="component"><VideoBck /></div>
      <div className="component"><Fruits /></div>
      <div className="component"><Vegetable /></div>
      <div className="component"><Hero /></div>
      <div className="component"><Galery /></div>
      <div className="component"><Testimonials/></div>
      <div className="component"><HeroBlog/></div>
    </div>
  );
};

export default HomePage;
