import React from "react";
import "./footer.scss";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="footer-wrapper">
        <div className="footer-logo">
          <img src={'/assets/img/brand/logo.png'} alt="" />
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure quos
            deleniti consequatur ut. Minus velit doloremque soluta! Animi,
            veniam. Facere?
          </p>
        </div>
        <div className="footer-container">
          <div>
            <ul>
              <li>
                <h1>Company</h1>
              </li>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about">About us</NavLink>
              </li>
              <li>
                <NavLink to="/product">Products</NavLink>
              </li>
              <li>
                <NavLink to="/blog">Blogs</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact Us</NavLink>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <h1>Our Product</h1>
              </li>
              <li>
                <NavLink to="/product:1">Raspberry</NavLink>
              </li>
              <li>
                <NavLink to="/product:2">Blackberry</NavLink>
              </li>
              <li>
                <NavLink to="/product:3">Sour Cherries</NavLink>
              </li>
              <li>
                <NavLink to="/product:4">Forest Fruits</NavLink>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <h1>Our Conatct</h1>
              </li>
              <li>+383 44 123 567</li>
              <li>info@minuszero.com</li>
              <li>rr. Filan Fisteku</li>
              <li>Prishtine, 100</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
