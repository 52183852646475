import "./App.css";
import { Navbar, Footer } from "./Components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  HomePage,
  AboutPage,
  ContactPage,
  BlogPage,
  ProductPage,
  SinglePage
} from "./Pages";

//comment test
function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/product/:productId" element={<SinglePage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
