import React from 'react'
import { RelatedProducts, SingleProduct } from '../Components'


const SinglePage = () => {
  window.scrollTo({ top: 0 })
  return (
    <div>
      <SingleProduct/>
      <RelatedProducts/>
    </div>
  )
}

export default SinglePage
