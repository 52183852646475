import React from "react";
import "./about.scss";
import { Link, NavLink } from "react-router-dom";
import { Testimonials } from '../'
import { products } from "../../Assets/data/server";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/free-mode'
import { FreeMode } from 'swiper/modules'
import { RightArrow } from "../../Assets";


const About = () => {
  return (
    <div className="about-section">
      <div className="about-header"></div>
      <div className="about-banner">
        <img src="/assets/img/banner/about.png" alt="" />
      </div>
      <div className="about-wrapper">
        <div className="about-txt">
          <h1>About us</h1>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, incidunt itaque minus recusandae ut in excepturi! Voluptates quidem non, voluptate quod, dolor commodi blanditiis laborum vitae aliquid quisquam animi modi ipsa, repellat doloremque facere. Facere sed consectetur magni dignissimos omnis atque eos, natus aliquam sit est architecto distinctio delectus eveniet sapiente earum! Quis sunt ex quibusdam ea praesentium ipsam incidunt explicabo, dolorem quisquam voluptatum magni aspernatur consequatur ducimus nobis reprehenderit? Cumque modi fuga provident, eveniet, officia culpa rerum in accusamus vel sunt perspiciatis fugit nihil adipisci hic repellendus et perferendis voluptatum harum qui? Architecto ea consequatur quae officia dignissimos dicta!</p>
        </div>
        <div className="mid-banner">
          <img src="/assets/img/banner/aboutbanner.png" alt="" />
        </div>
        <div className="about-txt">
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, incidunt itaque minus recusandae ut in excepturi! Voluptates quidem non, voluptate quod, dolor commodi blanditiis laborum vitae aliquid quisquam animi modi ipsa, repellat doloremque facere. Facere sed consectetur magni dignissimos omnis atque eos, natus aliquam sit est architecto distinctio delectus eveniet sapiente earum! Quis sunt ex quibusdam ea praesentium ipsam incidunt explicabo, dolorem quisquam voluptatum magni aspernatur consequatur ducimus nobis reprehenderit? Cumque modi fuga provident, eveniet, officia culpa rerum in accusamus vel sunt perspiciatis fugit nihil adipisci hic repellendus et perferendis voluptatum harum qui? Architecto ea consequatur quae officia dignissimos dicta!</p>
        </div>
        <div className="about-content">
          <div className="box">
            <h1>30+</h1>
            <p>Vite</p>
          </div>
          <div className="box">
            <h1>1500t</h1>
            <p>Fruta</p>
          </div>
          <div className="box">
            <h1>50+</h1>
            <p>Puntore</p>
          </div>
          <div className="box">
            <h1>8</h1>
            <p>Produkte</p>
          </div>
        </div>
        <div className="about-video">
          <video src="/assets/videos/video1.mp4" autoPlay loop muted />
        </div>
        <div className="product-carousel">
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 2,
                spaceBetween: 15
              },
              700: {
                slidesPerView: 4,
                spaceBetween: 15
              }
            }}
            FreeMode={true}
            modules={[FreeMode]}
            style={{
              maxWidth: '100%'
            }}
          >
            {products.map((el) => {
              return(
                <SwiperSlide id="swipeslides" key={el.id}>
                <div
                className="product-card"
                key={el.id}
                style={{ backgroundColor: el.backgroundColor }}
              >
                <div className="product-image">
                  <img
                    src={'/assets/img/bck/bck.png'}
                    alt=""
                  />
                  <div>
                      <img src={el.backgroudPic} alt="bck-pic" className="fruit-bck"/>
                      <img src={el.image} />
                      <img src={el.backgroudPicUp} alt="bck-pic-up" className="fruit-bck-up" />
                  </div>
                </div>
                <div className="product-functions">
                  <h1>{el.title}</h1>
                  <NavLink to={`/product/${el.id}`}>
                    <button>
                      <RightArrow />
                    </button>
                  </NavLink>
                </div>
              </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        <div className="about-container">
          <div className="left">
            <h1>Our Mision</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat velit fuga porro dolores. Eveniet sunt facilis esse, perspiciatis laboriosam commodi quas qui culpa porro, repellat cupiditate vitae. Praesentium, aspernatur. Quidem assumenda nostrum a officiis quod ad incidunt deleniti error laudantium magni alias vitae exercitationem deserunt quisquam quis, soluta dolorem explicabo consectetur esse voluptatem nam vero reprehenderit? Rerum mollitia tempora aliquid. Non commodi eum reprehenderit deserunt maiores aut placeat necessitatibus dolorum similique? Enim totam nostrum sit magnam recusandae, similique delectus laborum blanditiis quo neque consectetur numquam eaque consequuntur aperiam consequatur explicabo odit inventore nobis, officiis assumenda quasi provident temporibus. Repellendus doloremque, voluptate numquam repudiandae consectetur iste pariatur amet, labore nobis tempore architecto vel officiis ex deserunt dicta. Nulla mollitia consequatur fuga, architecto ducimus nihil cupiditate amet numquam facere expedita facilis praesentium dolore commodi, quam perspiciatis tempore debitis assumenda delectus possimus eos dolorum nostrum! Eveniet doloremque, necessitatibus eligendi iste dicta cum, sit sunt, nesciunt quisquam ipsa accusamus eius nemo deleniti iure commodi? Nam eveniet eum omnis minima delectus iste? Impedit exercitationem fugiat vitae placeat nisi illo commodi, harum vero! Incidunt velit eum rerum eos enim quo neque! Maxime ea soluta repellendus exercitationem ratione officia iste ipsam quis? Laborum enim iste dicta voluptatum!</p>
          </div>
          <div className="right">
            <img src="/assets/img/bck/blueBck.png" alt="" />
          </div>
        </div>
        <div className="about-banner">
          <div className="banner-left">
            <h1>See more product from us</h1>
            <Link to='/product'>
            <button>See more</button>
            </Link>
          </div>
          <div className="banner-right">
            <img src="/assets/img/bck/bck.png" alt="" className="about-bck"/>
            <div>
            <img src="/assets/img/products/raspberry.png" alt="" className="about-product"/>
            </div>
          </div>
        </div>
        <Testimonials/>
        
      </div>
    </div>
  );
};

export default About;
