export const products = [
    {
        id: 1,
        title: "Raspberry",
        price: "6.99",
        size: "450gr",
        size1: "600gr",
        size2: "800gr",
        backgroudPic: '/assets/img/ext/rasp2.png',
        backgroudPicUp: '/assets/img/ext/rasp1.png',
        image: '/assets/img/products/raspberry.png',
        backgroundColor: "#B91112",
        bckbanner: 'radial-gradient(circle, rgba(185,17,18,1) 0%, rgba(130,12,13,1) 60%, rgba(0,0,0,1) 100%)',
        desc: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        desc1: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        category: 'fruits',
    },
    {
        id: 2,
        title: "Blackberry",
        price: "6.99",
        size: "450gr",
        size1: "600gr",
        size2: "800gr",
        backgroudPic: '/assets/img/ext/black2.png',
        backgroudPicUp: '/assets/img/ext/black1.png',
        image: '/assets/img/products/blackberry.png',
        backgroundColor: "#331947",
        bckbanner: 'radial-gradient(circle, rgba(109,113,176,1) 0%, rgba(76,79,123,1) 60%, rgba(0,0,0,1) 100%)',
        desc: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        desc1: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        category: 'fruits',
    },
    {
        id: 3,
        title: "Cherries",
        price: "6.99",
        size: "450gr",
        size1: "600gr",
        size2: "800gr",
        backgroudPic: '/assets/img/ext/cherry2.png',
        backgroudPicUp: '/assets/img/ext/cherry1.png',
        image: '/assets/img/products/cherries.png',
        backgroundColor: "#C62932",
        bckbanner: 'radial-gradient(circle, rgba(198,41,50,1) 0%, rgba(139,29,35,1) 60%, rgba(0,0,0,1) 100%)',
        desc: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        desc1: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        category: 'fruits',
    },
    {
        id: 4,
        title: "Forest",
        price: "8.99",
        size: "450gr",
        size1: "600gr",
        size2: "800gr",
        backgroudPic: '/assets/img/ext/rasp2.png',
        backgroudPicUp: '/assets/img/ext/black1.png',
        image: '/assets/img/products/forest.png',
        backgroundColor: "#C53B96",
        bckbanner: 'radial-gradient(circle, rgba(197,59,150,1) 0%, rgba(138,41,105,1) 60%, rgba(0,0,0,1) 100%)',
        desc: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        desc1: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        category: 'fruits',
    },
    {
        id: 5,
        title: "Strawberry",
        price: "6.99",
        size: "450gr",
        size1: "600gr",
        size2: "800gr",
        backgroudPic: '/assets/img/ext/rasp2.png',
        backgroudPicUp: '/assets/img/ext/rasp1.png',
        image: '/assets/img/products/strawberry.png',
        backgroundColor: "#DF262D",
        bckbanner: 'radial-gradient(circle, rgba(223,38,45,1) 0%, rgba(156,27,31,1) 60%, rgba(0,0,0,1) 100%)',
        desc: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        desc1: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        category: 'fruits',
    },
    {
        id: 6,
        title: "Blueberry",
        price: "6.99",
        size: "450gr",
        size1: "600gr",
        size2: "800gr",
        backgroudPic: '/assets/img/ext/blue2.png',
        backgroudPicUp: '/assets/img/ext/blue1.png',
        image: '/assets/img/products/blueberry.png',
        backgroundColor: "#1D9BBF",
        bckbanner: 'radial-gradient(circle, rgba(29,155,191,1) 0%, rgba(20,109,134,1) 60%, rgba(0,0,0,1) 100%)',
        desc: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        desc1: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        category: 'fruits',
    },
    {
        id: 7,
        title: "Boletus Edulis",
        price: "6.99",
        size: "450gr",
        size1: "600gr",
        size2: "800gr",
        backgroudPic: '/assets/img/ext/mush2.png',
        backgroudPicUp: '/assets/img/ext/mush1.png',
        image: '/assets/img/products/mushrooms.png',
        backgroundColor: "#7F2818",
        bckbanner: 'radial-gradient(circle, rgba(127,40,24,1) 0%, rgba(89,28,17,1) 60%, rgba(0,0,0,1) 100%)',
        desc: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        desc1: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        category: 'vegetable',
    },
    {
        id: 8,
        title: "Chanterelle",
        price: "8.99",
        size: "450gr",
        size1: "600gr",
        size2: "800gr",
        backgroudPic: '/assets/img/ext/chanterelle2.png',
        backgroudPicUp: '/assets/img/ext/chanterelle1.png',
        image: '/assets/img/products/chanterelle.png',
        backgroundColor: "#F7B51C",
        bckbanner: 'radial-gradient(circle, rgba(247,181,28,1) 0%, rgba(173,127,20,1) 60%, rgba(0,0,0,1) 100%)',
        desc: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        desc1: 'is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using',
        category: 'vegetable',
    },
]
export const blogs = [
    {
        id: 1,
        title: 'Blog1',
        image: '/assets/img/blog/blog1.png',
        desc: 'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
        link: 'https://eurofruti.com/'
    },
    {
        id: 2,
        title: 'Blog2',
        image: '/assets/img/blog/blog1.png',
        desc: 'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
        link: 'https://eurofruti.com/'
    },
    {
        id: 3,
        title: 'Blog3',
        image: '/assets/img/blog/blog1.png',
        desc: 'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
        link: 'https://eurofruti.com/'
    },
    {
        id: 4,
        title: 'Blog4',
        image: '/assets/img/blog/blog1.png',
        desc: 'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
        link: 'https://eurofruti.com/'
    },
    {
        id: 5,
        title: 'Blog5',
        image: '/assets/img/blog/blog1.png',
        desc: 'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
        link: 'https://eurofruti.com/'
    },
    {
        id: 6,
        title: 'Blog6',
        image: '/assets/img/blog/blog1.png',
        desc: 'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
        link: 'https://eurofruti.com/'
    },
    {
        id: 7,
        title: 'Blog7',
        image: '/assets/img/blog/blog1.png',
        desc: 'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
        link: 'https://eurofruti.com/'
    },
    {
        id: 8,
        title: 'Blog8',
        image: '/assets/img/blog/blog1.png',
        desc: 'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
        link: 'https://eurofruti.com/'
    },
    {
        id: 9,
        title: 'Blog9',
        image: '/assets/img/blog/blog1.png',
        desc: 'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
        link: 'https://eurofruti.com/'
    },
]

export const banner = [
    {
        id: 1,
        title: 'Raspberry',
        desc: 'Raspberry fruti me i mire, Raspberry fruti me i mire, Raspberry fruti me i mire, Raspberry fruti me i mire',
        pruductPic: '/assets/img/products/raspberry.png',
        frontPic: '/assets/img/ext/rasp1.png',
        backPic: '/assets/img/ext/rasp2.png',
        backgroundColor: 'radial-gradient(circle, rgba(185,17,18,1) 0%, rgba(130,12,13,1) 60%, rgba(0,0,0,1) 100%)'
    },
    {
        id: 2,
        title: 'Blackberry',
        desc: 'Blackberry fruti me i mire, Raspberry fruti me i mire, Raspberry fruti me i mire, Raspberry fruti me i mire',
        pruductPic: '/assets/img/products/blackberry.png',
        frontPic: '/assets/img/ext/black1.png',
        backPic: '/assets/img/ext/black2.png',
        backgroundColor: 'radial-gradient(circle, rgba(109,113,176,1) 0%, rgba(76,79,123,1) 60%, rgba(0,0,0,1) 100%)'
    },
    {
        id: 3,
        title: 'Forest',
        desc: 'Forest mix fruti me i mire, Raspberry fruti me i mire, Raspberry fruti me i mire, Raspberry fruti me i mire',
        pruductPic: '/assets/img/products/forest.png',
        frontPic: '/assets/img/ext/blue1.png',
        backPic: '/assets/img/ext/blue1.png',
        backgroundColor: 'radial-gradient(circle, rgba(197,59,150,1) 0%, rgba(138,41,105,1) 60%, rgba(0,0,0,1) 100%)'
    },
    {
        id: 4,
        title: 'Sour Cherries',
        desc: 'Cherries fruti me i mire, Raspberry fruti me i mire, Raspberry fruti me i mire, Raspberry fruti me i mire',
        pruductPic: '/assets/img/products/cherries.png',
        frontPic: '/assets/img/ext/cherry1.png',
        backPic: '/assets/img/ext/cherry2.png',
        backgroundColor: 'radial-gradient(circle, rgba(198,41,50,1) 0%, rgba(139,29,35,1) 60%, rgba(0,0,0,1) 100%)'
    }
]