import React from "react";

const WhitePack = () => {
  return (
    <svg
      viewBox="0 0 30 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.4046 1H1.6367V3.22845L4.31614 15.7767V29.28L1 35.0368V37H26.8659V35.0368L24.0803 22.3294L28.4046 3.22845V1Z"      
        stroke-width="2"
      />
      <path
        d="M7 1H23.7826V9.3913C23.7826 14.0257 20.0257 17.7826 15.3913 17.7826C10.7569 17.7826 7 14.0257 7 9.39131V1Z"
        stroke-width="2"
      />
    </svg>
  );
};

export default WhitePack;
