import React from "react";

const Wish = () => {
  return (
    <svg
      width="40"
      height="35"
      viewBox="0 0 36 33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.65 0.499999L25.6492 0.500001C22.6687 0.504579 19.8625 1.8486 18 4.1347C16.1375 1.8486 13.3313 0.504579 10.3508 0.5H10.35C5.06796 0.5 0.5 5.359 0.5 10.8637C0.5 16.1811 3.51623 21.5804 9.07605 26.4907L9.0764 26.491C11.653 28.7622 14.4793 30.7364 17.5014 32.3767L17.5013 32.3768L17.5119 32.3822C17.8183 32.5393 18.1817 32.5393 18.4881 32.3822L18.4882 32.3823L18.4987 32.3767C21.5197 30.7364 24.3451 28.7618 26.9209 26.4909L26.9211 26.4907C32.4837 21.5804 35.5 16.1811 35.5 10.8637C35.5 5.359 30.932 0.5 25.65 0.499999ZM17.0714 6.53986L17.0796 6.55425L17.0887 6.56806C17.2908 6.87518 17.6339 7.05853 18 7.05853C18.3661 7.05853 18.7092 6.87518 18.9113 6.56806L18.9204 6.55425L18.9286 6.53986C20.2964 4.12769 22.8638 2.63236 25.6504 2.62728C29.7232 2.62754 33.3667 6.45676 33.3667 10.8637C33.3667 16.0565 30.0476 20.6294 26.3711 24.0903C22.9512 27.3096 19.3022 29.4911 18 30.2236C16.6978 29.4911 13.0488 27.3096 9.6289 24.0903C5.95236 20.6294 2.63333 16.0565 2.63333 10.8637C2.63333 6.45676 6.27681 2.62754 10.3496 2.62728C13.1362 2.63236 15.7036 4.12769 17.0714 6.53986Z"
        strokeWidth='1'
      />
    </svg>
  );
};

export default Wish;
