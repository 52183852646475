import React from 'react'
import { Contact } from '../Components'

const ContactPage = () => {
  window.scrollTo({ top: 0 })
  return (
    <div>
      <Contact/>
    </div>
  )
}

export default ContactPage
