import React, { useEffect } from 'react'
import Home from './Home';
import HomeMobile from './HomeMobile';

const Banner = () => {
    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])

    const isMobileScreen = () => {
        return window.innerWidth <= 768;
    };

    // if (isMobileScreen()) {
    //     return (
    //         <React.Fragment>
    //             <HomeMobile />
    //         </React.Fragment>
    //     )
    // }
    // else {
    //     return (
    //         <React.Fragment>
    //             <Home />
    //         </React.Fragment>
    //     );
    // }
    return (
        <React.Fragment>
            <Home />
        </React.Fragment>
    );
}

export default Banner