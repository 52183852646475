import React from "react";
import "./testimonials.scss";
import { Star } from "../../Assets";

const Testimonials = () => {
  return (
    <div className="testimonials-wrapper">
      <div className="testimonials-title">
        <p>Our testimonials</p>
        <h1>Customers review</h1>
      </div>
      <div className="testimonials-container">
        <div className="comments-box">
          <div className="top">
            <div className="rates">
                <Star/>
                <Star/>
                <Star/>
                <Star/>
                <Star/>
            </div>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Provident, corrupti magni! Qui dicta veniam atque quisquam sequi, nobis deserunt corrupti?</p>
          </div>
          <div className="bot">
            <div className="left">
                <h1>Filan Fisteku</h1>
                <p>Store customer</p>
            </div>
          </div>
        </div>
        <div className="main-box">
          <div className="top">
            <div className="rates">
                <Star/>
                <Star/>
                <Star/>
                <Star/>
                <Star/>
            </div>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Provident, corrupti magni! Qui dicta veniam atque quisquam sequi, nobis deserunt corrupti?</p>
          </div>
          <div className="bot">
            <div className="left">
                <h1>Filan Fisteku</h1>
                <p>Store customer</p>
            </div>
          </div>
        </div>
        <div className="comments-box">
          <div className="top">
            <div className="rates">
                <Star/>
                <Star/>
                <Star/>
                <Star/>
                <Star/>
            </div>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Provident, corrupti magni! Qui dicta veniam atque quisquam sequi, nobis deserunt corrupti?</p>
          </div>
          <div className="bot">
            <div className="left">
                <h1>Filan Fisteku</h1>
                <p>Store customer</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
