import React from "react";
import { blogs } from "../../Assets/data/server";
import { NavLink } from 'react-router-dom'
import "./blog.scss";

const HeroBlog = () => {
  return (
    <div className="hero-blog-wrapper">
      <div className="hero-blog-title">
        <p>Our blogs</p>
        <h1>Latest blogs</h1>
      </div>
      <div className="hero-blog-container">
        <div className="blogs">
          {blogs.slice(0, 3).map((el) => {
            return (
              <div className="blog-card" key={el.id}>
                <div className="card-img">
                  <img src={el.image} alt="no-pic" />
                </div>
                <div className="card-content">
                  <h1>{el.title}</h1>
                  <p>{el.desc}</p>
                  <button>Read more</button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="nav-button">
            <NavLink to='/blog'><button>See more</button></NavLink>
        </div>
      </div>
    </div>
  );
};

export default HeroBlog;
