import React from "react";
import "./contact.scss";

const Contact = () => {
  return (
    <div className="contact-wrapper">
      <div className="contact-header"></div>
      <div className="contact-banner">
        <img src="/assets/img/banner/map.png" alt="" />
      </div>
      <div className="contact-container">
        <div className="contact-content">
          <div className="info-box">
            <div className="icon">
              <img src="/assets/img/icons/cell.png" alt="" />
            </div>
            <div className="txt">
              <h1>Call us</h1>
              <p>+383 44 111 222</p>
            </div>
          </div>
          <div className="info-box">
            <div className="icon">
              <img src="/assets/img/icons/email.png" alt="" />
            </div>
            <div className="txt">
              <h1>Email us</h1>
              <p>info@minuszero.com</p>
            </div>
          </div>
          <div className="info-box">
            <div className="icon">
              <img src="/assets/img/icons/pin.png" alt="" />
            </div>
            <div className="txt">
              <h1>Our location</h1>
              <p>Prishtine, 10000, Kosove</p>
            </div>
          </div>
        </div>
        <div className="contact-form-wrapper">
          <div className="contact-title">
            <h1>Na kontaktoni</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, beatae ducimus. Ex voluptas a error aut esse beatae. Odio, ratione.</p>
          </div>
          <div className="contact-form">
            <div className="name">
              <input type="text" placeholder="Emri"/>
              <input type="text" placeholder="Mbiemri"/>
            </div>
            <input type="text" placeholder="Email"/>
            <input type="text" placeholder="Numri i telefonit"/>
            <textarea name="" placeholder="Mesazhi"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
