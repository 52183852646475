import React, { useState } from "react";
import "./home.scss";
import { banner } from "../../Assets/data/server";

const Home = () => {
  const [productArr, setProductArr] = useState([1, 0, 3]);
  const [frontArr, setFrontArr] = useState([1, 0, 3]);
  const [backArr, setBackArr] = useState([0, 1, 2]);
  const handleProductClicked = (e) => {
    switch (e.target.id) {
      case "raspberry":
        setProductArr([1, 0, 3]);
        setFrontArr([1, 0, 3]);
        setBackArr([1, 0, 3]);
        break;
      case "forest":
        setProductArr([3, 2, 1]);
        setFrontArr([3, 2, 1]);
        setBackArr([3, 2, 1]);
        break;
      case "blackberry":
        setProductArr([2, 1, 0]);
        setFrontArr([2, 1, 0]);
        setBackArr([2, 1, 0]);
        break;
      case "cherry":
        setProductArr([0, 3, 2]);
        setFrontArr([0, 3, 2]);
        setBackArr([0, 3, 2]);
        break;
      default:
        break;
    }
  };

  const handlePreviewClicked = () => {
    // Raise index by 1
  const newProductArr = productArr.map(index => (index + 1) % 4);
    setProductArr(newProductArr);
    setFrontArr(newProductArr);
    setBackArr(newProductArr);
  };

  const handleViewedClicked = () => {
    // Lower index by 1
  const newProductArr = productArr.map(index => (index - 1 + 4) % 4);
    setProductArr(newProductArr);
    setFrontArr(newProductArr);
    setBackArr(newProductArr);
  };

  return (
    <div className="home-wrapper">
      <div className="home-container">
        <div className="left-container">
          <div className="home-content">
            <h1>raspberry</h1>
            <p>
              Eurofruti company boasts an impressive collection of approximately
              500-1000 tons of Conventional Raspberry annually, with a notable
              portion of 300-4... tons attributed to the Meeker variety.
            </p>
            <button><span>See more</span></button>
          </div>
          <div className="home-product">
            <div className="home-product-inner">
              <img
                // onClick={handleProductClicked}
                data="0"
                id="raspberry"
                src={"/assets/img/products/raspberry.png"}
                alt=""
              />
              <img
                // onClick={handleProductClicked}
                data="1"
                id="blackberry"
                src={"/assets/img/products/blackberry.png"}
                alt=""
              />
              <img
                // onClick={handleProductClicked}
                data="2"
                id="forest"
                src={"/assets/img/products/forest.png"}
                alt=""
              />
              <img
                // onClick={handleProductClicked}
                data="3"
                id="cherry"
                src={"/assets/img/products/cherries.png"}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="right-container">
          <img src={"/assets/img/bck/bck.png"} alt="" className="bck" />
          <div className="slider-wrapper">
            <div className="slider-container">
              {banner.map((product, index) => {
                if (
                  (frontArr[1] === index || backArr[1] === index) &&
                  productArr[1] === index
                ) {
                  return (
                    <div className="slider-inner">
                      <img
                        src={product.backPic}
                        alt=""
                        className="assets inView1"
                      />
                      <img
                        src={product.frontPic}
                        alt=""
                        className="assets inView"
                      />
                      <div className="slider-product inview">
                        <img src={product.pruductPic} alt="" className="product-slider inview" />
                      </div>
                    </div>
                  );
                }
                if (
                  (frontArr[2] === index || backArr[2] === index) &&
                  productArr[2] === index) {
                  return (
                    <div className="slider-inner">
                      <img
                        src={product.backPic}
                        alt=""
                        className="assets out"
                      />
                      <img
                        src={product.frontPic}
                        alt=""
                        className="assets out"
                      />
                      <div className="slider-product viewed">
                        <img src={product.pruductPic} alt="" onClick={handleViewedClicked} className="product-slider viewed" />
                      </div>
                    </div>
                  );
                }
                if (
                  (frontArr[0] === index || backArr[0] === index) &&
                  productArr[0] === index) {
                  return (
                    <div className="slider-inner">
                      <img
                        src={product.backPic}
                        alt=""
                        className="assets next1"
                      />
                      <img
                        src={product.frontPic}
                        alt=""
                        className="assets next"
                      />
                      <div className="slider-product preview zhigh">
                        <img src={product.pruductPic} alt="" onClick={handlePreviewClicked} className="product-slider preview zhigh" />
                      </div>
                    </div>
                  );
                }
                else {
                  return (
                    <div className="slider-inner">
                      {/* <img
                        src={product.backPic}
                        alt=""
                        className="assets inView1"
                      />
                      <img
                        src={product.frontPic}
                        alt=""
                        className="assets inView"
                      /> */}
                      <div className="slider-product preview">
                        <img src={product.pruductPic} alt="" className="product-slider preview" />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
