import React from 'react'
import Product from '../Components/Product/Product'

const ProductPage = () => {
  window.scrollTo({ top: 0 })
  return (
    <div>
      <Product/>
    </div>
  )
}

export default ProductPage
