import React, { useState } from "react";
import "./product.scss";
import { RightArrow } from "../../Assets";
import { NavLink } from "react-router-dom";
import { products } from "../../Assets/data/server";

const Product = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");

  const filterProducts = (category) => {
    setSelectedCategory(category);
  };

  const filteredProducts = selectedCategory === "all" 
    ? products 
    : products.filter(product => product.category === selectedCategory);


  return (
    <div className="product-section">
      <div className="product-header">
        <h1>Produktet</h1>
        <img src={'/assets/img/bck/bck.png'} alt="" className="bck"/>
      </div>
      <div className="product-wrapper">
        <div className="product-select">
          <button
            className="select-inner"
            style={{
              backgroundColor: selectedCategory === "fruits" ? "#72C6DA" : "transparent",
              border: "2px solid #72C6DA",
              color: selectedCategory === "fruits" ? "#fff" : "#000",
            }}
            onClick={() => filterProducts("fruits")}
          >
            <img src={'/assets/img/banner/mixbanner.png'} alt="fruits" />
            <h1>Fruta të ngrira</h1>
          </button>
          <button
            className="select-inner"
            style={{
              backgroundColor: selectedCategory === "vegetable" ? "#65338F" : "transparent",
              border: "2px solid #65338F",
              color: selectedCategory === "vegetable" ? "#fff" : "#000",
            }}
            onClick={() => filterProducts("vegetable")}
          >
            <img src={'/assets/img/banner/mixbanner.png'} alt="vegetable" />
            <h1>Perime të ngrira</h1>
          </button>
        </div>
        <div className="product-container">
        {filteredProducts.map((el) => {
            return (
              <div
                className="product-card"
                key={el.id}
                style={{ backgroundColor: el.backgroundColor }}
              >
                <div className="product-image">
                  <img
                    src={'/assets/img/bck/bck.png'}
                    alt=""
                  />
                  <div>
                      <img src={el.backgroudPic} alt="bck-pic" className="fruit-bck"/>
                      <img src={el.image} />
                      <img src={el.backgroudPicUp} alt="bck-pic-up" className="fruit-bck-up" />
                  </div>
                </div>
                <div className="product-functions">
                  <h1>{el.title}</h1>
                  <NavLink to={`/product/${el.id}`}>
                    <button>
                      <RightArrow />
                    </button>
                  </NavLink>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Product;
