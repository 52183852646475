import React from "react";
import "./video.scss";
import { motion } from 'framer-motion'

const VideoBck = () => {
  const slideVariants = {
    initial: {
      y: 150,
      opacity: 0,
      scale: .5,
    },
    animate: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        duration: .7,
      }
    }
  }
  return (
    <div className="video-wrapper">
      <video src="/assets/videos/video1.mp4" autoPlay loop muted />
      <div className="video-container">
        <div className="row">
          <div className="left">
            <div className="title">
              <p>
                Kompania <span>EuroFruti</span> eshte nje kompani e njohur ne
                Kosove dhe me gjere
              </p>
            </div>
          </div>
          <div className="right">
            <motion.div className="box" variants={slideVariants}
              initial="initial"
              whileInView="animate"
            >
              <h1>30+</h1>
              <p>vite</p>
            </motion.div>
            <motion.div className="box" variants={slideVariants}
              initial="initial"
              whileInView="animate"
            >
              <h1>1500t</h1>
              <p>fruta</p>
            </motion.div>
          </div>
        </div>
        <div className="row">
          <div className="left">
          <motion.div className="box" variants={slideVariants}
              initial="initial"
              whileInView="animate"
            >
              <h1>27h</h1>
              <p>Toke me fruta dhe perime</p>
            </motion.div>
          </div>
          <div className="right">
            <motion.div className="box" variants={slideVariants}
              initial="initial"
              whileInView="animate"
            >
              <h1>30+</h1>
              <p>vite</p>
            </motion.div>
            <motion.div className="box" variants={slideVariants}
              initial="initial"
              whileInView="animate"
            >
              <h1>1500t</h1>
              <p>fruta</p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBck;
