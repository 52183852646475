import React from "react";

const RightArrow = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_2" fill="#fff" data-name="Layer 2">
        <path d="m3 13.25a1.25 1.25 0 0 1 0-2.5h18a1.25 1.25 0 0 1 0 2.5z" />
        <path d="m15 19.25a1.24 1.24 0 0 1 -.88-2.13l5.11-5.12-5.11-5.12a1.24 1.24 0 1 1 1.76-1.76l6 6a1.23 1.23 0 0 1 0 1.76l-6 6a1.22 1.22 0 0 1 -.88.37z" />
      </g>
    </svg>
  );
};

export default RightArrow;
