import React from 'react'
import './galery.scss'

const Galery = () => {
    return (
        <div className='gallery-wrapper'>
            <div className="gallery-title">
                <p>our gallery</p>
                <h1>Our Photo</h1>
            </div>
            <div className="gallery-container">
                <div className="left">
                    <img src="/assets/img/gallery/gallery1.png" alt="" />
                </div>
                <div className="right">
                    <div className="top">
                        <img src="/assets/img/gallery/gallery2.png" alt="" />
                    </div>
                    <div className="bot">
                        <img src="/assets/img/gallery/gallery3.png" alt="" />
                        <img src="/assets/img/gallery/gallery4.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Galery