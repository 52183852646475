import React from 'react'
import { Blog } from '../Components'

const BlogPage = () => {
  window.scrollTo({ top: 0 })
  return (
    <div>
      <Blog/>
    </div>
  )
}

export default BlogPage
