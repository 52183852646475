import React from "react";
import './singleproduct.scss'
import { NavLink } from "react-router-dom";
import { RightArrow } from "../../Assets";
import { products } from "../../Assets/data/server";

const RelatedProducts = () => {
  return (
    <div className="related-section">
      <div className="related-wrapper">
        <h1>Related products</h1>
        <div className="related-container">
          {products.slice(0, 4).map((el) => {
            return (
              <div
                className="related-card"
                key={el.id}
                style={{ backgroundColor: el.backgroundColor }}
              >
                <div className="product-image">
                  <img src={'/assets/img/bck/bck.png'} alt=""/>
                  <div>
                      <img src={el.backgroudPic} alt="bck-pic" className="fruit-bck"/>
                      <img src={el.image} />
                      <img src={el.backgroudPicUp} alt="bck-pic-up" className="fruit-bck-up" />
                  </div>
                </div>
                <div className="product-functions">
                  <h1>{el.title}</h1>
                  <NavLink to={`/product/${el.id}`}>
                    <button>
                      <RightArrow />
                    </button>
                  </NavLink>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RelatedProducts;
