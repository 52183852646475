import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import './navbar.scss'
import { Wish } from "../../Assets";
import { IoMdClose } from "react-icons/io";
import { MdOutlineMenu } from "react-icons/md";

const Navbar = () => {
    const [toggleBtn, setToggleBtn] = useState(false);

    const toggleMenu = () => {
        if (window.innerWidth <= 768) {
            setToggleBtn(!toggleBtn);
            document.body.classList.toggle("scroll-y");
            window.scrollTo(0, 0);
        }
    }

    return (
        <div className={'navbar-wrapper'}>
            <div className="navbar-container">
                <NavLink to="/"><img src={'/assets/img/brand/logo.png'} alt="logo" /></NavLink>
                <div className={`${toggleBtn ? "mobile-menu" : ""} menu`}>
                    <div className="mobile-logo">
                        <NavLink to="/" onClick={toggleMenu}><img src={'/assets/img/brand/logo.png'} alt="logo" /></NavLink>
                    </div>
                    <NavLink to="/" onClick={toggleMenu}>Home</NavLink>
                    <NavLink to="/product" onClick={toggleMenu}>Products</NavLink>
                    <NavLink to="/about" onClick={toggleMenu}>About us</NavLink>
                    <NavLink to="/blog" onClick={toggleMenu}>Blogs</NavLink>
                    <NavLink to="/contact" onClick={toggleMenu}>Contact Us</NavLink>
                    <NavLink><Wish/></NavLink>
                </div>
                <button className={`btn-menu ${toggleBtn ? 'close' : ''}`} onClick={toggleMenu}>
                    {toggleBtn ? <IoMdClose /> : <MdOutlineMenu />}
                </button>
            </div>
        </div>
    );
};

export default Navbar;

