import React from 'react'
import About from '../Components/About/About'

const AboutPage = () => {
  window.scrollTo({ top: 0 })
  return (
    <div>
       <About/>
    </div>
  )
}

export default AboutPage
