import React from "react";
import "./style.scss";
import { raspberryAssets } from "../../Assets";
import { motion } from 'framer-motion'
import { NavLink } from "react-router-dom";
import { products } from "../../Assets/data/server";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/free-mode'
import { FreeMode } from 'swiper/modules'
import { RightArrow } from "../../Assets";
import { useMediaQuery } from '@mui/material'

const Vegetable = () => {
  const isSmallDev = useMediaQuery('(max-width: 768px)');
  const slideVariants = {
    initial: {
      rotate: (90),
      opacity: 0,
      scale: .5
    },
    animate: {
      rotate: (0),
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
      }
    }
  }

  const vegetableProducts = products.filter(product => product.category === 'vegetable');

  return (
    <div
      className="hero-wrapper"
      style={{ backgroundColor: "#6D71B0", color: "#fff" }}
    >
      <div className="hero-container">
        <div className="hero-left">
          <img src={'/assets/img/bck/bck.png'} alt="" className="bck" />
          <motion.div
            className='hero-animation'
            variants={slideVariants}
            initial="initial"
            whileInView="animate"
          >
            <img src={'/assets/img/ext/fruitAssets.png'} alt="" className="fruits" />
          </motion.div>
          <img src={'/assets/img/banner/mixbanner.png'} alt="" className="banner" />
        </div>
        <div className="hero-right">
          <div className="right-bck">
            <img src={'/assets/img/bck/strokebck1.png'} alt="" />
          </div>
          <div className="hero-content">
            <h1>Perime të ngrira</h1>
            <p>
              Eurofruti company boasts an impressive collection of approximately
              500-1000 tons of Conventional Raspberry annually, with a notable
              portion of 300-4... tons attributed to the Meeker variety. To
            </p>
            <NavLink to='/product'><button style={{ backgroundColor: '#fff', color: '#000' }}>See more</button></NavLink>
          </div>
          <div className="product-carousel" style={{
            width: isSmallDev ? '100%' : '65%',//should be removed when more products vegetable icrease on database
          }}>
          <Swiper
            breakpoints={{
              340: {
                slidesPerView: 2,
                spaceBetween: 15
              },
              700: {
                slidesPerView: 2,//should be changed to slidesPerView: 3.2, when more products vegetable icrease on database
                spaceBetween: 15
              }
            }}
            FreeMode={true}
            modules={[FreeMode]}
            style={{
              maxWidth: '100%'
            }}
          >
            {vegetableProducts.map((el) => {
              return(
                <SwiperSlide id="swipeslides" key={el.id}>
                <div
                className="product-card"
                key={el.id}
              >
                <div className="product-image">
                  <img
                    src={'/assets/img/bck/bck.png'}
                    alt=""
                  />
                  <div>
                      <img src={el.backgroudPic} alt="bck-pic" className="fruit-bck"/>
                      <img src={el.image} />
                      <img src={el.backgroudPicUp} alt="bck-pic-up" className="fruit-bck-up" />
                  </div>
                </div>
                <div className="product-functions">
                  <h1>{el.title}</h1>
                  <NavLink to={`/product/${el.id}`}>
                    <button>
                      <RightArrow />
                    </button>
                  </NavLink>
                </div>
              </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Vegetable;
