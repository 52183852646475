import React from "react";
import { blogs } from "../../Assets/data/server";
import "./blog.scss";

const Blog = () => {
  return (
    <div className="blog-wrapper">
      <div className="blog-header"></div>
      <div className="blog-container">
        <div className="blog-banner">
          <img src="/assets/img/banner/banner.png" alt="" />
        </div>
        <div className="blog-txt">
          <h1>Blogs</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
            expedita cupiditate nesciunt est sequi optio tenetur cum veritatis,
            voluptatum sed, placeat architecto ullam tempore cumque consequuntur
            debitis totam a laborum ducimus? Harum voluptatum eos vitae fugiat
            explicabo odio aliquid obcaecati, error ab eum, incidunt quam ullam,
            sunt fugit eveniet aspernatur?
          </p>
          <button>Read More</button>
        </div>
        <div className="blogs">
          {blogs.map((el) => {
            return (
              <div className="blog-card" key={el.id}>
                <div className="card-img">
                  <img src={el.image} alt="no-pic" />
                </div>
                <div className="card-content">
                  <h1>{el.title}</h1>
                  <p>{el.desc}</p>
                  <button>Read more</button>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="blog-buttons">
          <button>See</button>
        </div> */}
      </div>
    </div>
  );
};

export default Blog;
