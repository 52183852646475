import React from 'react'

const Star = () => {
  return (
<svg width="37" height="35" viewBox="0 0 37 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.4621 0.574219L22.679 13.5525H36.3252L25.2852 21.5736L29.5021 34.5519L18.4621 26.5308L7.42206 34.5519L11.639 21.5736L0.598963 13.5525H14.2452L18.4621 0.574219Z" fill="#FFD600"/>
</svg>

  )
}

export default Star
