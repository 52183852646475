import React from "react";
import "./singleproduct.scss";
import { WhitePack, Wish } from "../../Assets";
import { useParams } from "react-router-dom";
import { products } from "../../Assets/data/server";

const SingleProduct = () => {
  window.scrollTo({ top: 0 })
  const { productId } = useParams();
  const product = products.find((el) => el.id === parseInt(productId));

  if (!product) {
    return <div>Product not found</div>;
  }
  return (
    <div className="single-section" style={{
      background: product.bckbanner
    }}>
      <div className="single-wrapper">
        <div className="single-left">
          <img src={'/assets/img/bck/bck.png'} alt="" />
          <div>
            <img src={product.backgroudPic} alt="bck-pic" className="fruit-bck" />
            <img src={product.image} />
            <img src={product.backgroudPicUp} alt="bck-pic-up" className="fruit-bck-up" />
          </div>
        </div>
        <div className="single-container">
          <div className="single-title">
            <h1>{product.title}</h1>
            <button><a><Wish /></a></button>
          </div>
          <div className="single-content">
            <p>
              {product.desc}
            </p>
            <div className="size-buttons">
              <button>
                <a><WhitePack /></a> {product.size}
              </button>
              <button>
                <a><WhitePack /></a> {product.size1}
              </button>
              <button>
                <a><WhitePack /></a> {product.size2}
              </button>
            </div>
            <div className="single-mark">
              <p>
                <span>100%</span> Natural
              </p>{" "}
              |
              <p>
                <span>No added</span> sugar
              </p>
            </div>
            <div className="single-desc">
              <h3>Description</h3>
            </div>
            <p>
              {product.desc1}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
